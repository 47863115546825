/* eslint-disable react/prop-types */
import React from "react";

function StatementFormatter({ text }) {
  // Define patterns to identify statements
  // "1\\.",
  // "2\\.",
  // "3\\.",
  // "4\\.",
  const statementPatterns = [
    "1\\.",
    "2\\.",
    "3\\.",
    "4\\.",
    "5\\.",
    "6\\.",
    "7\\.",
    "8\\.",
    "Statement1",
    "Statement2",
    "Statement3",
    "Statement4",
    "Statement -I",
    "Statement -II",
    "Statement -III",
    "Statement -IV",
  ];

  //   const questionPatterns = ["who", "which", "how", "what", "why", "select"];

  // Define question-based words
  const questionWords = ["how", "select", "where", "which", "what", "who", "when"];

  // Split the text into an array of statements based on the patterns
  const statements = text.split(new RegExp(`(${statementPatterns.join("|")})`, "g"));

  // Initialize formatted text array
  const formattedText = [];

  // Loop through statements
  statements.forEach((statement, index) => {
    // Check if the statement is empty or null
    if (!statement) return;

    // Add statement to formattedText

    formattedText.push(
      <React.Fragment key={index}>
        {index !== 0 && statement.match(new RegExp(`^(${statementPatterns.join("|")})$`)) ? (
          <br />
        ) : null}
        {/* Add <br> tag before each pattern, except for the first one */}
        {statement}
      </React.Fragment>
    );
  });

  return <div>{formattedText}</div>;
}

export default StatementFormatter;
